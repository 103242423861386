<template>
	<order-print v-if="print_show" :uuid="uuid" @printClose="printClose"></order-print>
	<div class="content" v-loading="loading" v-show="!print_show">
		<div class="order_detail">订单详情<el-button type="primary" style="margin-left: 10px;" @click="print">打印</el-button></div>
		<el-divider content-position="left">订单详情</el-divider>
		<el-row class="detail_row" id="printTest">
			<el-col :span="12" class="">
				订单号：{{address_data.uuid}}
			</el-col>
			<el-col :span="12" class="">
				下单时间：{{address_data.create_time}}
			</el-col>
			<el-col :span="12" class="col">
				订单状态：{{address_data.is_finish_str}}
			</el-col>
			<el-col :span="12" class="col">
				总金额：{{address_data.price}}
			</el-col>
			<el-col :span="12" class="col">
				下单用户：{{address_data.user_name}}
			</el-col>
		</el-row>

		<el-divider content-position="left">地址</el-divider>
		<el-row class="row">
			<el-col :span="12" class="col">
				<el-input class="input" v-model="address_data.address_name" placeholder="请输入收获人">
					<template #prepend>
						收获人
					</template>
				</el-input>
			</el-col>
			<el-col :span="12" class="col">
				<el-input class="input" v-model="address_data.address_mobile" placeholder="请输入联系电话">
					<template #prepend>
						联系电话
					</template>
				</el-input>
			</el-col>
			<el-col :span="24" class="col">
				<el-input v-model="address_data.address" class="address" maxlength="30" placeholder="请输入收货地址" show-word-limit type="textarea" />
			</el-col>
			<el-col :span="12" class="col" style="margin-top: 25px;">
				<span style="font-size: 17px;">付款方式：</span>
				<el-radio-group v-model="address_data.pay_method" class="ml-4">
				  <el-radio label="1" size="large">货到付款</el-radio>
				  <el-radio label="2" size="large">欠</el-radio>
				  <el-radio label="3" size="large">开发票</el-radio>
				</el-radio-group>
			</el-col>
			<el-col :span="12" class="col" style="margin-top: 20px;">
				<el-input class="input" v-model="address_data.remark" placeholder="请输入备注">
					<template #prepend>
						顶部备注
					</template>
				</el-input>
			</el-col>
			
			<el-col :span="24" class="row_btn"><el-button type="primary" @click="addressSubmit">提交</el-button></el-col>
		</el-row>


		<el-divider content-position="left">备注</el-divider>
		<el-row class="row">
			<el-col :span="12" class="col">
				<el-input class="input" v-model="remark_data.remark1" placeholder="请输入备注">
					<template #prepend>
						备注
					</template>
				</el-input>
			</el-col>
			<el-col :span="12" class="col">
				<el-input class="input" v-model="remark_data.money1" placeholder="请输入金额">
					<template #prepend>
						金额 <el-select v-model="remark_data.symbol1"  slot="prepend" 
							style="width: 80px;margin-left: 20px;	" placeholder="符号">
							  <el-option label="+" value="+"></el-option>
							  <el-option label="-" value="-"></el-option>
							  <el-option label="*" value="*"></el-option>
							  <el-option label="/" value="/"></el-option>
							</el-select>
					</template>
				</el-input>
			</el-col>
			<el-col :span="12" class="col" style="margin-top: 10px;">
				<el-input class="input" v-model="remark_data.remark2" placeholder="请输入备注">
					<template #prepend>
						备注
					</template>
				</el-input>
			</el-col>
			<el-col :span="12" class="col" style="margin-top: 10px;">
				<el-input class="input" v-model="remark_data.money2" placeholder="请输入金额">
					<template #prepend>
						金额 <el-select v-model="remark_data.symbol2"  slot="prepend" 
							style="width: 80px;margin-left: 20px;	" placeholder="符号">
							  <el-option label="+" value="+"></el-option>
							  <el-option label="-" value="-"></el-option>
							  <el-option label="*" value="*"></el-option>
							  <el-option label="/" value="/"></el-option>
							</el-select>
					</template>
				</el-input>
			</el-col>
			<el-col :span="24" class="row_btn"><span style="color: red;margin-right: 20px;">注：备注填写后才会触发</span><el-button type="primary" @click="remarkSubmit">提交</el-button></el-col>
		</el-row>


		<el-divider content-position="left">商品</el-divider>
		<el-table :data="tableData" style="width: 95%;margin-left: 2.5%;" border>
			<el-table-column prop="goods_img" label="缩略图">
				<template #default="scope">
					<el-image style="width: 100px; height: 100px" :src="scope.row.goods_img" :fit="fit" />
				</template>
			</el-table-column>
			<el-table-column prop="goods_code" label="商品编号">
			</el-table-column>
			<el-table-column prop="goods_name" label="商品名称">
				<template #default="scope">
					{{ scope.row.goods_name }} {{ scope.row.norms_value }}
				</template>
			</el-table-column>
			<el-table-column prop="num" label="数量" width="250">
				<template #default="scope">
					<el-input-number v-model="scope.row.num" @change="changeNum(scope.row)" :min="1" />
				</template>
			</el-table-column>
			<el-table-column prop="price" label="单价" />
			<el-table-column prop="count_price" label="总金额">
				<template #default="scope">
					{{ scope.row.num * scope.row.price }}
				</template>
			</el-table-column>
			<el-table-column prop="count_price" label="操作">
				<template #default="scope">
					<el-popconfirm title="是否删除商品？" @confirm="del(scope.row.uuid)" confirm-button-text="是" cancel-button-text="否">
						<template #reference>
							<el-button type="danger" size="mini">
								<el-icon><Close /></el-icon>
							</el-button>
						</template>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
	</div>
	
	
</template>

<script setup>
import { reactive, ref, defineEmits, inject, defineProps, onMounted } from 'vue';
import { success, error } from '@/utils/tips.js';
import orderPrint from './orderPrint.vue'
const dialogVisible = ref(true);
const loading = ref(false);
const $post = inject('$post');
const $get = inject('$get');
const emit = defineEmits(['close']);
const props = defineProps(['uuid']);
const print_show=ref(false);
const address_data = ref({
	uuid: props.uuid,
	address: '',
	address_mobile: '',
	address_name: '',
	remark:'',
	pay_method:'',
});
const remark_data=ref({
	"uuid":props.uuid,
	"remark1":"","symbol1":"","money1":"",
	"remark2":"","symbol2":"","money2":"",
});
const tableData = ref([]);
onMounted(() => {
	if (props.uuid != '') find();
});
const print=async()=>{
	print_show.value=!print_show.value;
}
const printClose=async()=>{
	print_show.value=!print_show.value;
}
const getPrice=async()=>{
	var price=0;
	tableData.value.forEach((item,index)=>{
		price=price+item.num*item.price;
	})
	address_data.value.price=price;
}
const changeNum = async item => {
	//进行传值
	loading.value = true;
	const res = await $post('/api/OrderGoods/ChangeNum', { uuid: item.uuid, num: item.num });
	loading.value = false;
	if (res.errCode == '0') {
		item.num = parseInt(res.result);
		error(res.msg);
		return;
	}
	getPrice();
};
const addressSubmit = async () => {
	loading.value = true;
	const res = await $post('/api/Order/UpdateAddress', address_data.value);
	loading.value = false;
};
const remarkSubmit=async()=>{
	loading.value = true;
	const res = await $post('/api/Order/UpdateRemark', address_data.value);
	loading.value = false;
}
const del = async uuid => {
	loading.value = true;
	const res = await $post('/api/OrderGoods/DelGoods', { uuid: uuid });
	loading.value = false;
	find();
};
const find = async () => {
	const res = await $get('/api/Order/OrderDetail', { uuid: props.uuid });
	address_data.value = res.result.address;
	remark_data.value = res.result.address;
	tableData.value = res.result.goods;
};
const submit = async () => {
	const res = await $post('/api/Classify/Add', form.value);
	if (res.errCode == '0') {
		error(res.msg);
		return;
	}
	success(res.msg);
	close();
};
const close = () => {
	emit('close');
};
</script>
<style>
.el-drawer__body {
	overflow: auto;
}
</style>
<style lang="scss" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 90%;margin-left: 5%;
}

.content {
	.order_detail {
		font-size: 30px;
		padding: 20px 20px;
	}
	.detail_row{
		padding: 20px;font-size: 18px;text-align: left;width: 90%;margin-left: 5%;
		.col{
			margin-top: 15px;
		}
	}
	.row {
		padding: 20px;
		.col {
			.input {
				width: 90%;
			}
		}
		.address {
			margin-top: 20px;
		}
		.row_btn {
			margin-top: 10px;
			text-align: right;
		}
	}
}
</style>
