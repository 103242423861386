<template>
	<!-- <el-dialog v-model="dialogVisible" title="打印" :fullscreen="true" draggable @close="printClose"> -->
		<div class="print_order">
			<el-button type="" style="margin-left: 10px;" @click="printClose">返回</el-button>
			<el-button type="primary" style="margin-left: 10px;" v-print="'#printTest'">打印</el-button>
			<div style="margin-top:1%"></div>
			<div id="printTest"  >
				 <!-- :style="['width:' + div_width + 'px']" -->
				<table  class="table"  border="3" style="font-size: 16px;font-weight: 700;"  cellspacing="0" cellpadding="0">
				  <thead >
					<tr>
						<td colspan="7" style="padding: 5px;">
							<span style="font-weight: 700;font-size: 18px;">发货者：777玩具（注：货款不可未经公司允许与业务员对接）</span>
						</td>
					</tr>
					<tr>
						<td colspan="7" style="padding: 5px;font-size: 18px;">
							<span>联系电话：0628892965/0628868859</span>
						</td>
					</tr>
					
					<tr>
						<td colspan="7" style="padding: 5px;">
							<div style="width:20%;float: left;text-align: center;">
								<img src="../../assets/ewm.jpg" style="height: 180px;"/>
							</div>
							<div style="width: 74%;float: left;line-height: 50px;text-align: left;margin-left: 6%;">
								<div style="width: 100%;float: left;height: 35px;">下单时间:{{basic.create_time}}</div>
								<div style="width: 49%;float: left;height: 35px;">业务员:{{basic.user_name}}</div>
								<div style="width: 49%;float: left;height: 35px;">订单号：{{basic.uuid}}</div>
								<div style="width: 49%;float: left;height: 35px;">订货人：{{basic.address_name}}</div>
								<div style="width: 49%;float: left;height: 35px;">联系电话: {{basic.address_mobile}}</div>
								<div style="width: 100%;float: left;height: 35px;">地址: {{basic.address}}</div>
								<div style="width: 50%;float: left;height: 35px;">付款方式: {{basic.pay_method_str}}</div>
								<div style="width: 50%;float: left;height: 35px;">备注: {{basic.remark}}</div>
							</div>
						</td>
					</tr>
					
					<tr >
					  <td style="padding: 3px;">序号</td>
					  <td style="padding: 3px;text-align: left;">商品编号</td>
					  <td style="padding: 3px;text-align: left;width:40%">商品名</td>
					  <td style="padding: 3px;">数量</td>
					  <td style="padding: 3px;">单价</td>
					  <td style="padding: 3px;">合计</td>
					  <td style="padding: 3px;">备注</td>
					</tr>
				  </thead>
				  <tbody style="font-size: 14px;font-weight: 800;">
					<tr v-for="(item,index) of tableData" >
						<td style="padding: 3px;">
							{{index+1}}
						</td>
						<td style="padding: 3px;text-align: left;">
							{{item.goods_code}}
						</td>
						<td style="padding: 3px;text-align: left;">
							{{item.goods_name}} {{item.norms_value}}
						</td>
						<td style="padding: 3px;">
							{{item.num}}
						</td>
						<td style="padding: 3px;">
							{{item.price}}
						</td>
						<td style="padding: 3px;" >
							<span v-if="index+1==tableData.length" style="font-size: 18px;">{{item.count_price}}</span>
							<span v-if="index+1!=tableData.length">{{item.count_price}}</span>
						</td>
						<td style="padding: 3px;">
							{{item.order}}
						</td>
					</tr>
					<!-- <tr>
						<td colspan="6" style="padding: 3px;text-align: right;">
							<span>总计：{{count_price}}</span>
						</td>
					</tr>
					<tr v-if="num_money!=''">
						<td colspan="1" style="padding: 3px;text-align: center;">
							<span>备注</span>
						</td>
						<td colspan="1" style="padding: 3px;text-align: left;">
							<span>{{form.yh_remark}}</span>
						</td>
						<td colspan="2" style="padding: 3px;text-align: right;">
							<span>{{count_price}}{{form.yh_money_fh}}{{form.yh_money_bl}}</span>
						</td>
						<td colspan="2" style="padding: 3px;text-align: right;">
							<span>总计：{{num_money}}</span>
						</td>
					</tr>
					
					<tr v-if="num_money1!=''">
						<td colspan="1" style="padding: 3px;text-align: center;">
							<span>备注</span>
						</td>
						<td colspan="1" style="padding: 3px;text-align: left;">
							<span>{{form.yh_remark1}}</span>
						</td>
						<td colspan="2" style="padding: 3px;text-align: right;">
							<span v-if="num_money!=''">{{num_money}}</span><span v-if="num_money==''">{{count_price}}</span><span>{{form.yh_money_fh1}}{{form.yh_money_bl1}}</span>
						</td>
						<td colspan="2" style="padding: 3px;text-align: right;">
							<span>总计：{{num_money1}}</span>
						</td>
					</tr> -->
					
				  </tbody>
				</table>
			</div>
		</div>
	<!-- </el-dialog> -->
</template>

<script setup>
import { ref, defineProps, onMounted, inject,defineEmits } from 'vue';
const dialogVisible = ref(true);
const props = defineProps(['uuid']);
const basic = ref({});
const $post = inject('$post');
const $get = inject('$get');
const emits=defineEmits(["printClose"]);
const tableData = ref([]);
const store_name = ref('发货者：徐盐荣百货（注：满一万蒂娜起现金付款不包含发票全场享折扣5%）');
onMounted(() => {
	find();
});
const find = async () => {
	const res = await $get('/api/Order/PrintOrderDetail', { uuid: props.uuid });
	basic.value = res.result.address;
	tableData.value = res.result.goods;
};
const printClose=async()=>{
	emits('printClose')
}
const div_width=document.getElementById('printTest').clientWidth;
console.log(div_width)
</script>
<style scoped lang="scss">
.print_order {
	padding: 20px;font-weight: 800;font-size: 30px;color: black;
}
.store_name {
	color: #44474e;
	text-align: center;
}
.order_text{
	padding: 8px;color: black;
}


.table{
		border:1px solid black;width: 100%;text-align: center;cellspacing:0;
		color:black;
	}
	.table th {
		background-repeat:repeat-x;
		height:30px;
	}
	.table td,.table th{
		border:1px solid black;
		padding:0 1em 0;
	}
	.table tr.alter{
		background-color:#f5fafe;
	}
</style>
